import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="orange">Kaushal Rajendra Khonde </span>
            from <span className="orange"> Gujarat, India.</span>
            <br />
            I currently work at Inexture Solutions as a DevOps Engineer.
            <br />
            I hold a B.Tech degree in Information Technology from Parul Institute Of Engineering & Technology and am currently pursuing an M.Tech in MLOps at the same institution.
            <br />
            <br />
            I also enjoy engaging in various other activities that bring me joy and fulfillment.
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Research on Trending New Technologies and writing Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Swimming and Playing TT
            </li>
            <li className="about-activity">
              <ImPointRight /> Practicing Karate
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Having the skills to play cricket doesn't guarantee the ability to excel in international cricket. Let the field experts execute their mastery #DevOps"{" "}
          </p>
          <footer className="blockquote-footer">DevOps Infinity & Beyond with Kaushal</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
