import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiKubernetes,
  SiTerraform,
  SiDocker,
  SiCloud,
  SiMacos,
} from "react-icons/si";

import { FaCloud } from "react-icons/fa";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiMacos />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKubernetes />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiTerraform />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FaCloud />
      </Col>
    </Row>
  );
}

export default Toolstack;
