import React from "react";

import { Col, Row } from "react-bootstrap";

import {
  SiLinux,
  SiJenkins,
  SiAnsible,
  SiSonarqube,
  SiNginx,
  SiApache,
  SiFirebase
} from "react-icons/si";

import {
  DiAws,
  DiGit,
  DiPostgresql, 
  DiReact,
  DiPython
} from "react-icons/di";

import {
  TbBrandGolang  
} from "react-icons/tb";

function Techstack() {

  return (
    <Row style={{justifyContent: "center", paddingBottom: "50px"}}>
    
      <Col xs={4} md={2} className="tech-icons">  
        <SiLinux />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiAws />  
      </Col>
      
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiSonarqube />
      </Col>

      <Col xs={4} md={2} className="tech-icons">  
        <SiNginx />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiApache />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiJenkins />  
      </Col>

      <Col xs={4} md={2} className="tech-icons">  
        <DiGit />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiPostgresql />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <SiAnsible />
      </Col>

      <Col xs={4} md={2} className="tech-icons">  
        <SiFirebase />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <TbBrandGolang />  
      </Col>

    </Row>
  );
}

export default Techstack;
